.App {
  text-align: center;
}

/* MAIN PAGE */
.waitingText {
  font-weight: bold;
  font-size: 25px;
}

.location {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px, solid, black;
  border-radius: 8px;
  font-weight: bold;
  font-size: 25px;

}

.imageColumn {
  display: flex;
  padding: 15px;
  border: 1px, solid, 	black;
  border-radius: 8px;
  width: 400px;
}

#image-container {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.suspiciousComponent {
  margin-left: 15px;
  margin-right: 15px;
  border: 3px, solid, 	#8b2323;
  border-radius: 8px;
}

.approvedComponent {
  margin-left: 15px;
  margin-right: 15px;
  border: 3px, solid, #136b1d;
  border-radius: 8px;
}

.suspiciousHighlightBorder {
  box-shadow: 0 0 15px rgb(196, 24, 24);
  transition: border 0.3s ease-in-out;
}

.approvedHighlightBorder {
  box-shadow: 0 0 15px rgb(25, 163, 12);
  transition: border 0.3s ease-in-out;
}

.suspiciousHeader {
  background-color: 	#8b2323;
  color: white;
  padding: 5px;
}

.suspiciousHeader1 {
  font-weight: bold;
  margin-bottom: 5px;
}

.approvedHeader {
  background-color: #136b1d;
  color: white;
  font-weight: bold;
}

.detailColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:65%
}

.date-eventId {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.sectionHeaderEventId {
  font-size: 14x;
  font-weight: bold;
  margin-left: 10%;
}

.date-eventId-gap {
  margin-left: 5px;
}

.vehicleDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.suspiciousEvent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: 	#f1d2d2;
}

.approvedEvent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.sectionHeader {
  font-size: 14x;
  font-weight: bold;
}

.devices {
  display: flex;
  width:100%;
  margin-bottom: 20px;
}

.nonVehicleDevices {
  margin-left: 20%;
}
